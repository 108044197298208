import React from 'react'

const Error404 = () => {
    return ( 
        <div>
            <h1>Error 404</h1>
            <p>La página no existe</p>
        </div>
     );
}
 
export default Error404;