import React from 'react';
import logo from '../images/Logo.png'
import '../style/components/Navbar.css'


function Navbar() {
  return (
    <React.Fragment>
      <nav>
        <div className='navbarContainer'>
          <a href='/inicio'>
          <img src={logo} alt='logo-nav' className='logoNav'/>
          </a>
          <ul>
            <li>
              <a href='/inicio'>Inicio</a>
            </li>
            <li>
              <a href='/nosotros'>Nosotros</a>
            </li>
            <li>
              <a href='/galeria'>Galería</a>
            </li>
            <li>
              <a href='/requisitos'>Requisitos de Matricula</a>
            </li>
            {/* <li>
              <a href='/noticias'>Noticias</a>
            </li> */}
          </ul>
        </div>
        {/*Se deja nota para hacer un login de estudiantes en el futuro en este espacio*/}
      </nav>
    </React.Fragment>
    )
}

export {Navbar};