import React from 'react';
import imgbien from '../images/imgbienvenido.jpg';
import '../style/components/Body.css'

function Body(){
  return(
    <React.Fragment>
      <body>
        <div className='bodyContainer'>
          <h1 className='homeSaludo'>
            BIENVENIDO
          </h1>
          <div className='textoSaludo'>
            <text>
              Siendo el juego actividad innata, el niño aprende jugando la alegría de una canción infantil, el sentido de observación, el valor de los colores, la importancia del gusto y el amor a la naturaleza. Nuestro calificado equipo de trabajo cuenta con profesorado Licenciado en Educación Preescolar, además de profesores externos en las áreas de inglés informática.
            </text>
            <div className='imagenBienvenida'>
              <img src={imgbien} alt='imageHome' />
            </div>
          </div>
        </div>
      </body>
    </React.Fragment>
  )
}

export {Body};