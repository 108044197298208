import React from 'react';
import {Navbar} from './Navbar.js';
import {Footer} from './Footer.js';
import { Slider } from './componentsJsx/Slider.jsx';
import { Slider2 } from './componentsJsx/Slider2.jsx';
import '../style/components/Galeria.css';

function Galeria() {
  return (
    <div>
      <Navbar />
      <div className='galeriaContainer'>
        <Slider />
        <Slider2 />
        <h1 className='alumnoTexto'>
          Eres Brillante
        </h1>
      </div>
      <Footer />
    </div>
  );
}

export {Galeria};