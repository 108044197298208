import React from "react";
import {Navbar} from '../../components/Navbar.js';
import {Body} from '../../components/Body.js'
import { Footer } from '../../components/Footer';


function Home(){
  return (
      <div>
        <Navbar />
        <Body />
        <Footer />
      </div>
  )
}

export {Home};