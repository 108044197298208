import React, { Fragment } from "react";
import '../style/components/Requisitos.css';
import im6 from '../images/im6.jpg';
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

function Requisitos() {
  return (
    <Fragment>
      <div>
      <Navbar />
        <div className='requisitosContainer'>
          <h1 className='titleRequisitos'>
            REQUISITOS DE MATRICULA
          </h1>
          <div className='textoRequisitos'>
            <text>
              ♥ Formulario de inscripción (se entrega en la institución educativa).
              <br />
              ♥ 4 fotografías tipo carné (3x4cm) en fondo blanco.
              <br />
              ♥ Certificado de afiliación de la EPS a la que pertenece.
              <br />
              ♥ Fotocopia de Registro Civil.
              <br />
              ♥ Fotocopia del carné de vacunas regular al día
              <br />
              ♥ Fotocopia del carné de vacunas COVID (Opcional)
            </text>
            <div className='imagenRequisitos'>
              <img src={im6} alt='imageRequisitos' />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  )
}

export { Requisitos };