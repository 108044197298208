import React from 'react';
import '../src/style/App/App.css';
import {Home} from '../src/pages/components/Home.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Nosotros } from './components/Nosotros.js';
import { Requisitos } from './components/Requisitos.js';
import { Galeria } from './components/Galeria.js';
import { Noticias } from './components/Noticias.js'
import Error404 from './components/Error404';

function App() {
  return (
    
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Error404 />} />
        <Route path="/" element={<Home />} />
        <Route path="/inicio" element={<Home />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/galeria" element={<Galeria />} />
        <Route path="/requisitos" element={<Requisitos />} />
        <Route path="/noticias" element={<Noticias />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
