import React from 'react';
import { Navbar } from './Navbar';
import { Footer } from './Footer';

function Noticias(){
    return (
        <div>
            <Navbar />
            <div>
                <h1>Noticias</h1>
            </div>
            <Footer />
        </div>
    )
}
export {Noticias};