import React from "react";
import '../style/components/Politicas.css'
import recuadroUno from '../images/recuadroUno.png'
import recuadroDos from '../images/recuadroDos.png'
import recuadroTres from '../images/recuadroTres.png'
import recuadroCuatro from '../images/recuadroCuatro.png'
import recuadroCinco from '../images/recuadroCinco.png'


function Politicas() {
  return (
    <React.Fragment>
      <div className='politicasContainer'>
        <h1 className='titlePoliticas'>
          POLITICAS DE CALIDAD
        </h1>
        <div className='textoPoliticas'>
          <text>
            La Política de Calidad del CENTRO LUDICO INFANTIL, JARDIN MELANIE KLEIN se fundamenta en el fortalecimiento de destrezas y desarrollo de habilidades básicas de los niños de edad pre-escolar desarrollando cada una de sus dimensiones, respetando su ritmo de aprendizaje, por medio de la pedagogía basada en los pilares de la primera infancia. Fundamentados en los principios católicos, el trabajo articulado con los padres de familia y demás comunidad educativa, con el fin de contribuir en su proyección como niños y niñas felices en la educación básica.
            <br />
            El Jardin cuenta un personal competente, en un ambiente familiar, que busca la satisfacción de los padres defamilia, comprometidos con el mejoramiento continuo en la prestación del servicio educativo de calidad y el cumplimiento de los requisitos legales aplicables.
          </text>
          <div className='objetivosCalidadContainer'>
            <h3 className="subtitle">
              OBJETIVOS DE CALIDAD
            </h3>
            <div className="containerVarios">
              <div className="row">
                <div className="imgUno">
                  <img src={recuadroUno} alt="" />
                  <text className="textRecuadroUno">
                    Afianzar en nuestros estudiantes las destrezas y habilidades básicas en cada una de las dimensiones del desarrollo integral.
                  </text>
                </div>
                <div className="imgDos">
                  <img src={recuadroDos} alt="" />
                  <text className="textRecuadroDos">
                    Fortalecer el proyecto pedagógico institucional, en cada uno de sus componentes a través de los pilares de la educación inicial y articular al que hacer pedagógico.
                  </text>
                </div>
                <div className="imgTres">
                  <img src={recuadroTres} alt="" />
                  <text className="textRecuadroTres">
                    Garantizar personal competente en su ejercicio profesional, en el trato con los niños de edad pre-escolar y con la comunidad educativa, mediante un adecuado proceso de selección y el fortalecimiento de sus competencias.
                  </text>
                </div>
                <div className="imgCuatro">
                  <img src={recuadroCuatro} alt="" />
                  <text className="textRecuadroCuatro">
                    Atender las necesidades integrales de nuestros niños, mediante un trato amable, cariñoso y asertivo que los haga sentir en un ambiente familiar.
                  </text>
                </div>
                <div className="imgCinco">
                  <img src={recuadroCinco} alt="" />
                  <text className="textRecuadroCinco">
                    Comprometernos en el mejoramiento continuo en la prestación del servicio educativo de calidad.
                  </text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export {Politicas};