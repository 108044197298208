import imagen1 from './galery/1.png';
import imagen2 from './galery/2.jpg';
import imagen3 from './galery/3.png';
import imagen4 from './galery/4.jpg';
import imagen5 from './galery/5.png';
import imagen6 from './galery/6.jpg';
import imagen7 from './galery/7.png';
import imagen8 from './galery/8.jpg';
import imagen9 from './galery/9.jpg';
import imagen10 from './galery/10.jpg';

export default [imagen1, imagen2, imagen3, imagen4, imagen5, imagen6, imagen7, imagen8, imagen9, imagen10]