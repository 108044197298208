import React from "react";
import '../style/components/Objetivo.css'
import im2 from '../images/im2.jpg'

function Objetivo() {
  return (
    <React.Fragment>
      <div className='objetivoContainer'>
        <h1 className='titleObjetivo'>
          OBJETIVO INSTITUCIONAL
        </h1>
        <div className='textoObjetivo'>
          <text>
            Prestar un servicio educativo en la ciudad de San Juan de Pasto con la modalidad del nivel de educación preescolar con caracter privado, ofreciendo servicios de docencia a la formación integral de niños de 6 años de edad.
            <br />
            <br />
            Brindamos una educación que obedece a principios éticos, morales, religiosos e intelectuales para el aprendizaje de las normas de convivencia social, inculcando valores de respeto, amor, solidaridad y alegría. Además desarrollamos en los niños la creatividad, la motricidad, el desarrollo intelectual y la socioafectividad necesarias para su educación posterior.
          </text>
          <div className='imagenObjetivo'>
            <img src={im2} alt='imageObjetivo' />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export {Objetivo};