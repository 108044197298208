import React from 'react';
import logo from '../images/Logo.png';
import '../style/components/Nosotros.css';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { Mision } from './Mision';
import {Objetivo} from './Objetivo';
import {Politicas} from './Politicas';


function Nosotros() {
    return(
        <div>
            <Navbar />
            <div className='nosotrosContainer'>
                <h1 className='titleNosotros'>
                    NOSOTROS
                </h1>
                <div className='textoNosotros'>
                    <text>
                        En asocio de profesoras Licenciadas de Educación pre-escolar se acordaron y coordinaron de unificar criterios de capacidad profesional y de capital financiero para crear y fundar un Centro de Educación Pre-escolar para niños menores de tres (3) años de edad cronológica y a la vez aportar cívicamente a un sector urbano que requería con urgencia y necesidad del servicio educativo en la ciudad de Pasto.
                        Se propuso de preparar y gestionar los elementos legales, técnicos y ambientales de investigar la factibilidad y necesidad para una justa fundación de un establecimiento educativo.
                        Somos calendario A, pero las matriculas están abiertas todo el año para los grados de Parvulos, Pre-Jardín, Jardín y Transición.
                    </text>
                    <div className='imagenNosotros'>
                        <img src={logo} alt='imageNosotros' />
                    </div>
                </div>
            </div>
            <Mision />
            <Objetivo />
            <Politicas />
            <Footer />
        </div>
    )
}

export {Nosotros};