import React from 'react';
import '../style/components/Footer.css'


function Footer() {
  return (
    <section className='footerGeneral'>
      <footer>
        <div className='footerContainer'>
          <div className='footerMargin'>
            <aside className='asideLine'>
              <h2>
                Contacto
              </h2>
              <text>
                Dirección: Calle 16 a # 31-29, Barrio Maridíaz
                <br />
                Teléfono: 60 + 2 + 7241963
                <br />
                Correo: direccion@jardinmelanieklein.com.co
              </text>
            </aside>
            <aside className='asideLine'>
              <h2>
                Sobre Nosotros
              </h2>
              <a href='/nosotros'>
                Politicas de Calidad
              </a>
              <br />
              <a href='/'>
                Manual de Convivencia
              </a>
              <br />
              <a href='//www.facebook.com/jardinmklein'>
                Facebook
              </a>
              <br />
              <a href='//www.instagram.com/jardinmelanieklein.pasto/'>
                Instagram
              </a>
            </aside>
            <aside className='asideLine'>
              <h2>
                Padres de Familia
              </h2>
              <a href='/'>
                Cronogramas
              </a>
              <br />
              <a href='/'>
                Formatos
              </a>
              <br />
              <a href='/'>
                Encuestas
              </a>
              <br />
              <a href='/requisitos'>
                Requisitos de Matricula
              </a>
            </aside>
            <br />
          </div>
          <section className='derechos'>
            &#xA9; CCR - Pasto, Colombia
          </section>
        </div>
      </footer>
    </section>
  )
}

export {Footer};