import React from "react";
import '../style/components/Mision.css'
import im7 from '../images/im7.jpg'

function Mision(){
    return(
        <React.Fragment>
            <div className='misionContainer'>
                <h1 className='titleMision'>
                    MISION
                </h1>
                <div className='textoMision'>
                    <text>
                    Ofrecer a los padres de familia la educación inicial a sus hijos para dar una enseñanza y aprendizaje en función integral específicamente en la filosofía de valores humanos.
                    formar y educar al aspirante que ingrese al Jardín con amor, respeto, buen trato y el desarrollo de programas con de nuestra formación integral, el amor por el arte, la introducción y la iniciación del idioma ingles y conocimientos básicos en sistemas.
                    <br/>
                    Se propuso de preparar y gestionar los elementos legales, técnicos y ambientales de investigar la factibilidad y necesidad para una justa fundación de un establecimiento educativo.
                    Somos calendario A, pero las matriculas están abiertas todo el año para los grados de Parvulos, Pre-Jardín, Jardín y Transición.
                    </text>
                    <div className='imagenMision'>
                        <img src={im7} alt='imageMision' />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export {Mision};