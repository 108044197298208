import imagen11 from './galery/11.jpg';
import imagen12 from './galery/12.jpg';
import imagen13 from './galery/13.jpg';
import imagen14 from './galery/14.jpg';
import imagen15 from './galery/15.jpg';
import imagen16 from './galery/16.jpg';
import imagen17 from './galery/17.jpg';
import imagen18 from './galery/18.jpg';
import imagen19 from './galery/19.jpg';
import imagen20 from './galery/20.png';


export default [imagen11, imagen12, imagen13, imagen14, imagen15, imagen16, imagen17, imagen18, imagen19, imagen20]