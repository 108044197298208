import React from 'react'
import imagenes2 from '../../images/Imagenes2.js'
import '../../style/components/Slider.css'
import {motion} from 'framer-motion/dist/framer-motion'

const Slider2 = () => {
    return(
        <motion.div className='silderContainer'>
          <motion.div className='slider' drag='x' dragConstraints={{right: 0, left: -2446.14 }} >
            {imagenes2.map(imagen => (
              <motion.div className='item'>
                <img src={imagen} alt='' />
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
    )
}

export {Slider2};